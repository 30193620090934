import React, { useState, useEffect } from "react"
import qs from "qs"
import { useStoreState } from "easy-peasy"
import { navigate, useLocation } from "@reach/router"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import { Box } from "@lib/ui/core"
import ExerciseSummary from "@components/Exercise/Summary"

import { getQuiz, getTopic } from "@services/exercise"

import Spinner from "@components/Spinner"
const SummaryPage = () => {
  const [content, setContent] = useState(null)
  const [topicList, setTopicList] = useState([])
  const accessToken = useStoreState(
    ({ localStorage }) => localStorage.auth.accessToken
  )

  const location = useLocation()

  const fetchData = async ({ id }) => {
    const data = await getQuiz({ id })
    const { items } = await getTopic({})
    setTopicList(items)
    setContent(data.item)
  }
  useEffect(() => {
    if (!accessToken) navigate("/")
    const { search } = location
    const searchParams = qs.parse(search.substring(1))
    const quizId = searchParams.quiz_id
    fetchData({ id: quizId })
  }, [])
  return (
    <Layout>
      <Box>
        <Container>
          {content ? (
            <ExerciseSummary content={content} topicList={topicList} />
          ) : (
            <Box pt={10} display="flex" justifyContent="center">
              <Spinner />
            </Box>
          )}
        </Container>
      </Box>
    </Layout>
  )
}

export default SummaryPage
