import React from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import { Box, Button, Typography, SvgIcon } from "@lib/ui/core"

import { ReactComponent as StarIcon } from "@assets/icons/star.svg"
import { ReactComponent as TickIcon } from "@assets/icons/tick.svg"
import { ReactComponent as CrossIcon } from "@assets/icons/cross.svg"

const StyledButton = styled(Button)`
  background-color: #007bff !important;
  padding: 0.25rem 1.5rem;
  border-radius: 22px;
  color: #fff !important;
`

const CustomChip = styled(Box)`
  display: inline-block;
  background-color: ${({ bgcolor }) => bgcolor};
  color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 25px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const BlueBar = styled.div`
  background-color: #81c0ff;
  height: 5px;
  width: 100%;
  border-radius: 5px;
`

const Summary = ({ content, topicList }) => {
  const {
    id,
    name,
    grade,
    question_type,
    questions_count,
    level,
    duration,
    questions,
    start_time,
    end_time,
  } = content
  console.log(content)
  console.log(questions)
  const formattedQuestion = questions.map((question, index) => ({
    number: index + 1,
    bookmarked: question.data.is_bookmarked,
    topic: topicList.find((topic) => topic.id === question.data.topic_id).name,
    skipped: question.is_skip,
    correct: question.is_correct,
    type: question.data.type,
  }))
  console.log(formattedQuestion)

  const gradeText = {
    "F.1": "中一",
    "F.2": "中二",
    "F.3": "中三",
    "F.4": "中四",
    "F.5": "中五/六",
  }
  const typeText = {
    mc: "多項選擇題 (MC)",
    lq: "長問題 (LQ)",
  }

  const startDate = new Date(start_time)
  const formattedStartDate = startDate
    .toLocaleString("en-GB")
    .substring(0, startDate.toLocaleString("en-GB").length - 3)
  const endDate = new Date(end_time)
  const formattedEndDate = endDate
    .toLocaleString("en-GB")
    .substring(0, endDate.toLocaleString("en-GB").length - 3)

  return (
    <Box p={1}>
      <Box py={4}>
        <Box display="flex" justifyContent="center" color="#4D4D4D">
          <Typography variant="subtitle1">練習總結</Typography>
        </Box>
        <BlueBar />
      </Box>
      <Box display="flex">
        <Box width="30%">
          <Box color="#4D4D4D" pb={1}>
            <Typography variant="subtitle2">{name}</Typography>
          </Box>
          <Box py={1}>
            <CustomChip bgcolor="#9D90F3">{gradeText[grade]}</CustomChip>
          </Box>
          <Box py={1}>
            <CustomChip bgcolor="#81C0FF">{typeText[question_type]}</CustomChip>
          </Box>
          <Box py={1}>
            <CustomChip bgcolor="#FE96A4">{questions_count}題</CustomChip>
          </Box>
          <Box py={1}>
            <CustomChip bgcolor="#68E8C5">
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" whiteSpace="nowrap">
                  難度
                </Box>
                <Box display="flex" alignItems="center" mx={1}>
                  <SvgIcon component={StarIcon} fontSize="1rem" color="#fff" />
                </Box>
                {level >= 2 && (
                  <Box display="flex" alignItems="center" mx={1}>
                    <SvgIcon
                      component={StarIcon}
                      fontSize="1rem"
                      color="#fff"
                    />
                  </Box>
                )}
                {level >= 3 && (
                  <Box display="flex" alignItems="center" mx={1}>
                    <SvgIcon
                      component={StarIcon}
                      fontSize="1rem"
                      color="#fff"
                    />
                  </Box>
                )}
              </Box>
            </CustomChip>
          </Box>
          <Box py={1}>
            <CustomChip bgcolor="#FFCA5E">{duration}分鐘時間</CustomChip>
          </Box>
          <Box py={1} color="#4D4D4D">
            開始時間： {formattedStartDate}
          </Box>
          <Box py={1} color="#4D4D4D">
            結束時間： {formattedEndDate}
          </Box>
        </Box>
        <Box width="70%">
          <Box display="flex" alignItems="center" width="100%" px={4} py={1}>
            <Box width="20%" color="#4DD4D">
              題目
            </Box>
            <Box width="70%" color="#4D4D4D">
              課題
            </Box>
            <Box
              width="10%"
              display="flex"
              justifyContent="center"
              color="#4D4D4D"
            >
              表現
            </Box>
          </Box>
          {formattedQuestion.map(
            ({ number, topic, skipped, correct, bookmarked, type }) => (
              <Box
                bgcolor="#F7F8F8"
                display="flex"
                alignItems="center"
                width="100%"
                px={4}
                py={1}
              >
                <Box display="flex" width="20%">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="50%"
                    bgcolor="#81C0FF"
                    color="#FFF"
                    padding="8px"
                    height="1.5rem"
                    width="1.5rem"
                  >
                    {number}
                  </Box>
                  {bookmarked && (
                    <Box display="flex" alignItems="center" pl={1}>
                      <SvgIcon
                        component={StarIcon}
                        fontSize="1rem"
                        color="#ffca5e"
                      />
                    </Box>
                  )}
                </Box>
                <Box width="70%" color="#4D4D4D">
                  {topic}
                </Box>
                <Box
                  width="10%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {skipped ? (
                    <Box color="#4D4D4D">Skip</Box>
                  ) : type === "mc" ? (
                    <Box display="flex" alignItems="center">
                      {correct ? (
                        <SvgIcon
                          component={TickIcon}
                          viewBox="0 0 28 28"
                          color="#68e8c5"
                        />
                      ) : (
                        <SvgIcon
                          component={CrossIcon}
                          viewBox="0 0 28 28"
                          color="#cf2929"
                        />
                      )}
                    </Box>
                  ) : (
                    <Box color="#4D4D4D">NA</Box>
                  )}
                </Box>
              </Box>
            )
          )}
        </Box>
      </Box>
      <Box py={2} display="flex" justifyContent="center">
        <StyledButton onClick={() => navigate("/exercise/select")} mx={1}>
          返回選擇模式
        </StyledButton>
        <StyledButton
          onClick={() => navigate(`/exercise/process?quiz_id=${id}`)}
          mx={1}
        >
          查看詳細
        </StyledButton>
      </Box>
    </Box>
  )
}

export default Summary
